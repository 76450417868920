.account-page-container {
  box-sizing: border-box;
  padding: 124px 64px;
  background-color: #ffffff;
  align-items: stretch;
  max-width: 562px;
  align-self: center;
}

.account-page-container-min-size {
  width: 100%;
}

.account-page-container-max-size {
  width: 562px;
}

.account-page-container-with-margin {
  margin-left: 40px;
  margin-right: 40px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
}

.account-page-header-image {
  width: 128px;
  height: 128px;
  align-self: center;
  margin-bottom: 64px;
}

.account-page-submit-button {
  margin-top: 80px;
  margin-bottom: 36px;
}

.account-page-error-message {
  margin-top: -18px;
  margin-bottom: 16px;
}
