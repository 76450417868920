.form-field-container {
  margin-bottom: 36px;
}

.form-field-label {
  margin-bottom: 13px;
}

.form-field-input {
  display: flex;
  flex-grow: 1;
  padding: 6px;
  font-size: 22px;
  border-width: 2px;
  border-color: #000000;
  background-color: #f2f2f2;
  color: #000000;
  border-style: solid;
  border-radius: 0px;
}

.form-field-input:focus {
  background-color: #ffffff;
}

.form-field-container-big {
  margin-bottom: 36px;

  .form-field-label {
    margin-bottom: 24px;
  }
}
