/**
 * General style configuration
*/

// colors
$color-page-background: #f0f0f0;

$color-text: #42495e;
$color-text-link: #42495e;
$color-text-link-highlight: #2189e9;
$color-text-label: #000000;

$color-button-background: #291e1e;
$color-button-background-hover: #262626;
$color-button-background-active: #000000;
$color-button-background-disabled: #939393;
$color-button-text: #ffffff;

// general styles
html {
  min-height: 100%;
  display: flex;
  flex: 1;
}
body {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  font-family: 'Noto Sans SC', sans-serif;
  background-color: $color-page-background;
}
div {
  display: flex;
  box-sizing: border-box;
}
body,
a > * {
  color: $color-text;
}
input,
textarea {
  font-family: 'Noto Sans SC', sans-serif;
}

// root container style
#root {
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
}

// text styles
.link,
.link:link,
.link:visited,
.link:hover,
.link:active {
  color: $color-text-link;
}
.link-highlight,
.link-highlight:link,
.link-highlight:visited,
.link-highlight:hover,
.link-highlight:active {
  color: $color-text-link-highlight;
}
.text-label {
  color: $color-text-label;
}

// button styles
.button-container {
  background-color: $color-button-background;
}
.button-container:hover {
  background-color: $color-button-background-hover;
}
.button-container:active {
  background-color: $color-button-background-active;
}
.button-container.disabled {
  background-color: $color-button-background-disabled;
}
.button-title {
  color: $color-button-text;
}

button:focus,
ul:focus {
  outline: none !important;
  box-shadow: none !important;
}

div[role='menubar'] {
  flex-direction: column;
}
