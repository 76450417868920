.container {
  flex-grow: 1;
}
.upload {
  min-width: 200px;
  min-height: 50px;
  height: 100%;
}
.visualBox {
  height: 100%;
  width: 100%;
}
