.tabContainer {
}
.tabContentContainer {
  width: 100%;
}
.basicInfoItem {
  margin: 10px 0;
}

.groupTitle {
  margin-block-end: 0.5em;
}

.multiLanguageInputLabel {
  width: 80px;
}

.iconImageContainer {
  width: 100px;
  height: 100px;
  overflow: initial;
  flex-grow: 0;
}
