.text {
  font-family: "Noto Sans SC", sans-serif;
}
.text-center {
  text-align: center;
}
.text-one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// types
.text-label {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.96px;
}
.text-big-label {
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 1.57px;
}
.text-title {
  font-size: 32px;
  font-weight: normal;
  letter-spacing: 1.39px;
  color: #000000;
}
.text-content {
  font-size: 28px;
  line-height: 42px;
  font-weight: 300;
  letter-spacing: 0.32px;
  color: #1b1b1b;
  margin-top: 0;
}
.text-content:last-child {
  margin-bottom: 0;
}
.text-error {
  font-size: 15px;
  color: #ff2424;
}
.text-link,
.text-link:visited,
.text-link:hover,
.text-link:active,
.text-link-highlight,
.text-link-highlight:visited,
.text-link-highlight:hover,
.text-link-highlight:active {
  font-size: 15px;
  letter-spacing: 0.65px;
  text-decoration: underline;
}