.panelVersionItemDetailContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.panelVersionItemContent {
  background-color: #ffffff;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;

  :global {
    pre {
      overflow: auto;
    }
  }
}
.container {
  flex-direction: column;
}
