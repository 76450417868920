.layout-basic {
  display: flex;
  justify-content: flex-start;
  align-items: "flex-start";
  border-color: none;
  flex-grow: 0;
  flex-direction: row;
}

.layout-row {
    flex-direction: row;
}

.layout-column {
    flex-direction: column;
}

.layout-grow {
    flex-grow: 1;
}

.layout-no-select {
    user-select: none;
}

.layout-align-items-center {
    align-items: center;
}

.layout-justify-content-center {
    justify-content: center;
}