.textarea {
  outline-style: none;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 21px;
  resize: none;
  overflow: hidden;
  font-weight: 300;
  letter-spacing: 0.25px;
}
