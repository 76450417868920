.errorTipContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorTipContent {
  height: auto;
  flex-grow: 0;
  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);
  min-width: 500px;
  max-width: 90%;
}
.title {
    margin-bottom: 0;
}
