.contentContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  :global {
    div[role="tablist"] > div:first-child {
      // background-color: red;
      margin: 0 -10px 10px -10px;
    }
  }
}
.tabContainer {
}
.tabContentContainer {
  width: 100%;
}
.basicInfoItem {
  margin: 10px 0;
}

.groupTitle {
  margin-block-end: 0.5em;
}

.multiLanguageInputLabel {
  width: 80px;
}

.iconImageContainer {
  width: 100px;
  height: 100px;
  overflow: initial;
  flex-grow: 0;
}