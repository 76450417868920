.navigation-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  padding: 0px 24px;
  height: 48px;
  // grid layout
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 24px;
  place-items: center center;
}
.navigation-bar-title {
  padding: 0 12px;
  font-size: 24px;
  font-weight: 300;
}
.navigation-bar-left-container {
  justify-content: flex-start;
  width: 100%;
}
.navigation-bar-right-container {
  justify-content: flex-end;
  width: 100%;
}
.navigation-bar-button-title-with-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}
.navigation-bar-button-title {
  font-size: 14px;
}
