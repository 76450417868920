.button-container {
  padding: 4px 53px;
  flex-grow: 0;
  cursor: pointer;
}
.button-container.disabled {
  cursor: not-allowed;
}
.button-title {
  line-height: 41px;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: 1.22px;
}
