.mainContainer {
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    overflow: scroll;
}
.contentContainer {
    width: 100%;
    max-width: 1000px;
    flex-direction: column;
    justify-content: flex-start;
    // height: 100%;
    flex-grow: 1;
    align-items: stretch;
    // overflow: hidden;
    padding: 24px;
    padding-top: 48px;
    padding-bottom: 96px;
}